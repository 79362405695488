<template>
    <transition-group
        tag="div"
        class="social-icons"
        @before-enter="beforeEnter"
        @enter="enter"
        appear>
            <a 
                v-for="(social, index) in socials" 
                :key="social.title" 
                :data-index="index" 
                :href="social.url" 
                class="social-icon" 
                :class="social.title" 
                rel="noopener noreferrer" 
                target="_blank">
                    <i :class="social.icon"></i>
            </a>
    </transition-group>
</template>

<script>
    import { ref } from 'vue'
    import gsap from 'gsap'

    export default {

        setup() {
            const socials = ref([
                {
                    title: 'linkedin',
                    url: 'https://www.linkedin.com/in/gratian-sichet/',
                    icon: 'fab fa-linkedin-in'
                },
                {
                    title: 'github',
                    url: 'https://github.com/gratians',
                    icon: 'fab fa-github'
                },
                {
                    title: 'instagram',
                    url: '',
                    icon: 'fab fa-instagram'
                }
            ])

            const beforeEnter = (el) => {
                el.style.opacity = 0
                el.style.transform = 'translateX(100%)'
            }

            const enter = (el, done) => {
                gsap.to(el, {
                    opacity: 1,
                    x: 0,
                    duration: 0.5,
                    onComplete: done,
                    delay: el.dataset.index * 0.2
                }, 5)
            }

            return { socials, beforeEnter, enter }
        }

    }
</script>

<style lang="scss" scoped>

    @import "../assets/styles/_colors.scss";
    @import "../assets/styles/_animations.scss";

    .social-icons {
        display: flex;
        justify-content: center;
        top: 299%;
        left: 0;
        right: 0;
        font-size: 1.2rem;
        z-index: 1;
        overflow: hidden;
        padding: .1em;
        position: absolute;

        @media (min-width: 640px) {
            flex-direction: column;
            justify-content: space-between;
            position: fixed;
            top: 50%;
            right: 1.2em;
            left: unset;
            transform: translateY(-50%);
        }

        .social-icon {
            cursor: pointer;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: grid;
            place-items: center;
            position: relative;
            margin: .3em;

            &::after {
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                border-radius: 50%;
                transition: .3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
                transform: scale(0);
                z-index: -1;
            }

            &:hover::after {
                transform: scale(1);
            }
        }
    }

    .linkedin::after {
        background-image: linear-gradient(120deg, $blue-color, darken($blue-color, 25%));
    }

    .github::after {
        background-image: linear-gradient(120deg, $purple-color, darken($purple-color, 35%));
    }

    .instagram::after {
        background-image: linear-gradient(40deg, $yellow-color 15%, $magenta-color, $purple-color);
    }

</style>