<template>
    <section v-if="projects" id="projects" class="projects">
        <div v-for="project in projects" :key="project.id" class="project-wrapper">
            <a 
                :href="project.url" 
                class="project" 
                target="_blank" 
                rel="noopener noreferrer" 
            >{{ project.title }}
                <div class="project-bg__container">
                    <img :src="project.image" class="project-bg" :alt="project.description">
                </div>
            </a>
        </div>
    </section>
</template>

<script>
    import gsap from 'gsap'

    export default {
        data() {
            return {
                projects: [
                    {
                        id: 1,
                        title: 'D@rom',
                        description: 'Entreprise batiment',
                        url: 'https://darom.fr/',
                        image: require('../assets/img/darom.jpg'),
                        logo: '',
                        techs: ['html5', 'css3', 'js'],
                        isCool: true
                    },
                    {
                        id: 2,
                        title: 'Parimis',
                        description: 'Projet de formation.',
                        url: 'https://gratian-parimis.zd.fr/',
                        image: require('../assets/img/parimis.jpg'),
                        logo: '',
                        techs: ['html5', 'css3', 'js'],
                        isCool: false
                    },
                    {
                        id: 3,
                        title: 'Le petit commerce',
                        description: 'Projet de formation.',
                        url: 'https://lpc.gratian.dev/',
                        image: require('../assets/img/lpc.jpg'),
                        logo: '',
                        techs: ['html5', 'css3', 'js', 'gsap'],
                        isCool: true
                    },
                    {
                        id: 4,
                        title: 'Lokisalle',
                        description: 'Projet de formation.',
                        url: 'https://lockisalle.herokuapp.com/',
                        image: require('../assets/img/lokisalle.jpg'),
                        logo: '',
                        techs: ['html5', 'css3', 'js', 'gsap', 'php'],
                        isCool: true
                    },
                    {
                        id: 5,
                        title: 'L\'arcenciel',
                        description: 'Projet de formation.',
                        url: 'https://larcenciel.xyz/',
                        image: require('../assets/img/larcenciel.jpg'),
                        logo: '',
                        techs: ['wordpress'],
                        isCool: false
                    }
                ]
            }
        },
        methods: {
            showImage() {
                const projects = document.querySelectorAll('.project')
            
                projects.forEach((el) => {
                    const image = el.querySelector('img');

                    el.addEventListener('mouseenter', () => {
                        gsap.to(image, 0.3, { autoAlpha: 1, y: '-50%', rotate: 0 })
                    })
                    
                    el.addEventListener('mouseleave', () => {
                        gsap.to(image, 0.3, { autoAlpha: 0, y: '50%', rotate: '-15deg' })
                    })
                })
            }
        },
        mounted: function() {
            this.showImage()
        },
    }
</script>

<style lang="scss" scoped>
    @import "../assets/styles/_colors.scss";
    @import "../assets/styles/_animations.scss";

    .projects{
        margin-block: 10em;

        @media(min-width: 640px) {
            margin-block: 5em 10em;
        }

        .project-wrapper {
            position: relative; 

            .project {
                font-size: clamp(5rem, 15vw, 10rem);
                letter-spacing: -2px;
                text-transform: uppercase;
                line-height: .9;
                transition: .25s cubic-bezier(.075,.82,.165,1);

                @media (hover:hover) and (pointer:fine) {
                    &:hover  {
                        font-weight: 900;
                    }
                }

                .project-bg__container {
                    width: 500px;
                    max-width: 100%;
                    height: 300px;
                    position: absolute;
                    top: -100%;
                    right: 0;
                    overflow: hidden;
                    pointer-events: none;
                    z-index: -1;
                    @media(min-width: 640px) {
                        top: -50%;
                    }
                    
                    .project-bg {
                        position: absolute;
                        top: 50%;
                        transform: rotate(-15deg);
                        pointer-events: none;
                        opacity: 0;
                        z-index: -10;
                    }
                }
            }
        }
    }
</style>