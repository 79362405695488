import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Missing from '../views/Missing.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'Missing',
		component: Missing
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router