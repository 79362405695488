<template>
    <header class="header">
        <div class="nav">
            <transition 
                @before-enter="beforeEnter"
                @enter="enter"
                appear>
                <router-link to="/" class="logo">{{ owner.logo }}</router-link>
            </transition>

            <transition-group
                tag="div"
                class="nav-links"
                @before-enter="beforeEnter"
                @enter="enter"
                appear>
                    <a v-for="(link, index) in navLinks" :key="link" :data-index="index" :href="link.url" class="nav-link">
                        {{ link.title }}
                    </a>
            </transition-group>
        </div>

        <div class="intro">
            <transition-group 
                tag="h1"
                @before-enter="beforeEnterHero"
                @enter="enterHero"
                appear>
                <span v-for="(letter, index) in owner.fullName" :key="letter" :data-index="index">{{ letter }}</span>
            </transition-group>

            <transition name="slide-up" appear>
                <p>{{ owner.job }}</p>
            </transition>
        </div>

        <transition name="fade" appear>
            <div class="scroll-down"></div>
        </transition>
    </header>
</template>

<script>
    import gsap from 'gsap'
    import { ref } from 'vue'

    export default {
        setup() {
            const owner = {
                logo: 'Gratian',
                fullName: 'Gratian Sichet',
                job: 'Développeur Web',
                url: 'https://gratian.dev',
            }

            const navLinks = ref([
                { title: 'projets', url: '#projects'},
                { title: 'apropos', url: '#about'}
            ])

            const beforeEnter = (el) => {
                el.style.opacity = 0
                el.style.transform = 'translateY(-100%)'
            }

            const enter = (el, done) => {
                gsap.to(el, {
                    opacity: 1,
                    y: 0,
                    duration: 0.3,
                    delay: el.dataset.index * 0.2,
                    onComplete: done
                }, 4)
            }

            const beforeEnterHero = (el) => {
                el.style.opacity = 0
                el.style.transform = 'translateY(-10%)'
            }

            const enterHero = (el, done) => {
                gsap.to(el, {
                    opacity: 1,
                    y: 0,
                    duration: 0.28,
                    delay: el.dataset.index * 0.12,
                    onComplete: done
                }, 1)
            }

            return { owner, navLinks, beforeEnter, enter, beforeEnterHero, enterHero }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../assets/styles/_colors.scss";
    @import "../assets/styles/_animations.scss";

    .header {

        .nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;

            .logo {
                color: $blue-color;
                font-family: Sign, sans-serif;
                font-size: clamp(1.5rem,3vw,2rem);
            }

            .nav-links {
                display: inline-block;
                font-size: 1.2rem;
                text-transform: uppercase;

                .nav-link {
                    display: inline-block;
                    background: linear-gradient(90deg, $blue-color 50%, $text-color 50%);
                    background-size: 200%;
                    background-clip: text;
                    background-position: right;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    transition: 0.5s;
                    margin-left: 1em;

                    &:last-of-type {
                        margin-left: 1em;
                    }

                    &:hover {
                        background-position: left;
                    }
                }
            }
        }

        .intro {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            transform: translate(-50%,-50%);
            user-select: none;
            overflow: hidden;

            h1 {
                display: inline-block;
                font-size: clamp(5rem, 10vw, 10rem);
                font-family: Sorren, serif;
                text-transform: uppercase;
                color: $text-color;
                letter-spacing: 2px;
                margin-bottom: -.35em;
                z-index: -1;

                span {
                    display: inline-block;
                }

                span[data-index="7"] {
                    margin: 0 .05em;
                }
            }

            p {
                font-family: Sign, sans-serif;
                font-size: clamp(3.5rem, 6vw, 5rem);
                background-size: 200% !important;
                background-image: linear-gradient(30deg, $green-color, $blue-color, $green-color);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                animation: GradientText 10s ease infinite !important;
                line-height: 1.4;
            }
        }

        .scroll-down {
            position: absolute;
            bottom: 4em;
            left: 50%;
            transform: translateX(-50%);
            width: 32px;
            font-size: 1.2rem;

            &::before, &::after {
                content: 'Scroll';
                position: absolute;
                top: 0;
                left: 0;
                text-transform: uppercase;
            }
                
            &::before {
                animation: scrollDown 3s ease-in-out infinite;
            }

            &::after {
                animation: scrollDown 3s ease-in-out -1.5s infinite;
            }
        }
    }
</style>