<template>
	<section class="missing-container">
		<div class="missing-title">
			<h1>Oops!</h1>
		</div>
		<div class="missing-subtitle">
			<p>404 - Page non trouvée.</p>
			<p>Il semblerait que vous soyez perdu !</p>
		</div>

		<router-link :to="{ name: 'Home' }" class="homeBtn">Accueil</router-link>
		
		<div class="zoro">
			<transition @before-enter="beforeEnter" @enter="enter" appear>
				<img src="../assets/img/zoro-wano-removebg-preview.png" class="zoro-bg" alt="">
			</transition>
			
			<transition @before-enter="beforeEnterQuote" @enter="enterQuote" appear>
				<div class="zoro-quote">
					<div class="zoro-text">
						<p>Hmm...</p>
						<p>C'était à gauche ou à droite?!?</p>
					</div>
					<div class="bubble-svg">
						<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 155 136">
							<path d="M66.1 1.5C30.4 1.5 1.5 22.9 1.5 46c0 18.1 17.9 33.5 42.8 39.3 1.5 14.8-1.3 39-8.5 48.1 10.8-12.5 22.4-33.6 26.6-45.7 1.2 0 2.5.1 3.7.1 35.7 0 64.6-18.7 64.6-41.8S101.8 1.5 66.1 1.5zM35.8 133.4c-.3.4-.7.8-1 1.1.4-.3.7-.7 1-1.1z"/>
						</svg>
					</div>
				</div>
			</transition>
		</div>
	</section>
    <Footer />
</template>

<script>
	import gsap from 'gsap'
	import Footer from '@/components/Footer.vue'
	export default {
		setup() {
			
		},
		components: {
			Footer
		},
		setup() {
			const beforeEnter = (el) => {
                el.style.opacity = 0
                el.style.transform = 'translateX(-100%)'
            }

            const enter = (el, done) => {
                gsap.to(el, {
                    opacity: 1,
                    x: 0,
                    duration: 1,
                    onComplete: done
                }, 1)
            }
			
			const beforeEnterQuote = (el) => {
                el.style.opacity = 0
            }

            const enterQuote = (el, done) => {
                gsap.to(el, {
                    opacity: 1,
                    duration: 1.5,
                    onComplete: done
                }, 2.5)
            }

			return { beforeEnter, enter, beforeEnterQuote, enterQuote }
		}
	}
</script>

<style lang="scss" scoped>
@import "../assets/styles/_animations.scss";
@import "../assets/styles/_colors.scss";

    .missing-container {
		text-align: center;

		.missing-title {
			font-family: 'Open Sans', sans-serif;
			font-size: clamp(4rem, 8vw, 12rem);
			letter-spacing: .5;
            background-size: 200% !important;
            background-image: linear-gradient(30deg, $green-color, $blue-color, $green-color);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: GradientText 10s ease infinite !important;
			
			h1 {
				letter-spacing: -7px;
			}
		}

		.missing-subtitle {
			font-weight: lighter;
			font-size: clamp(1rem, 2vw, 1.2rem);
			margin-block: .5em;

			@media(min-width: 640px) {
				margin-block: 1.5em;
			}

			p:first-child {
				font-weight: 800;
				margin-bottom: 1em;
				text-transform: uppercase;
			}
		}

		.homeBtn {
			display: inline-block;
			background: linear-gradient(to left, transparent 50%, $blue-color 50%) right;
			background-size: 200% !important;
			border: 1px solid $blue-color;
			border-top-left-radius: 1em;
			border-bottom-right-radius: 1em;
			font-size: 1.2rem;
			font-weight: bolder;
			text-transform: uppercase;
			padding: .5em 2em;
			margin-block: .5em;
			transition: .3s ease;
			will-change: background;

			@media(min-width: 640px) {
				margin-block: 1em;
			}
			
			&:hover {
				background-position: left;
			}
		}

		.zoro {
			position: absolute;
			bottom: 0;
			left: -8%;
			z-index: -1;
			width: 10em;
			font-family: AnimeAce;

			@media(min-width: 640px) {
				width: 15em;
				left: -2%;
			}

			.zoro-quote {
				position: absolute;
				top: -50%;
				left: 40%;
                z-index: -1;

				@media(min-width: 640px) {
					top: -45%;
					left: 45%;
				}

				.zoro-text {
					color: $background-color;
					font-size: .6rem;
					position: absolute;
					top: 11%;
					left: 10%;
					right: 0;
					width: 10em;


					@media(min-width: 640px) {
                        font-size: .8rem;
						top: 12%;
						left: 11%;
					}
				}

				.bubble-svg {
					width: 9em;

					@media(min-width: 640px) {
						width: 12em;
					}
					path { 
						fill: $text-color;
						stroke: $green-color;
						stroke-width: 1.5;
					}
				}
			}
		}
	}

    footer {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 1em;

		@media(min-width: 640px) {
			padding: 2em;
		}
	}
</style>