import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import './assets/global.scss'
import './assets/styles/_colors.scss'

//npm install @fortawesome/font-awesome-free
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

createApp(App).use(router).mount('#app')
