<template>
    <section id="about" class="about-container">
        <div class="about-desc">
            <p>Je suis Gratian, développeur web autodidacte passionné par le front-end.</p>
            <br>
            <p>Mon attrait pour le développement, la soif d’apprendre et le goût du challenge m’ont poussé à poursuivre une formation intensive en tant que développeur intégrateur web.</p>
            <br>
            <p>Disponible de suite à Voisins-le-Bretonneux, Paris et environs ou en remote dans toute la France.</p>
        </div>

        <div class="about-bg"></div>
        <div class="top-fade"></div>
        <div class="left-fade"></div>
        <div class="right-fade"></div>
        <div class="bottom-fade"></div>
    </section>
</template>

<script>
    export default {  }
</script>

<style lang="scss">
    .about-container {
        display: flex;
        align-items: center;
        position: relative;
        height: 100vh;

        .about-desc {
            position: relative;
            font-size: clamp(1.5rem, 2vw, 2.3rem);
            line-height: 1.3;
            z-index: 1;
            // width: 20em;
            max-width: 20em;
        }

        .about-bg, 
        .top-fade, 
        .left-fade, 
        .right-fade,
        .bottom-fade {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;

            @media (min-width:640px) {
                width: 50%;
            }
        }

        .about-bg {
            background: url("../assets/img/me.jpg") center center;
            background-size: cover;
        }

        .top-fade {
            background: linear-gradient(0deg, rgba(16, 20, 38, 0) 20%, rgba(16, 20, 38, 1));
        }
        
        .left-fade {
            background: linear-gradient(270deg, rgba(16, 20, 38, 0) 50%, rgba(16, 20, 38, 1) 100%);
        }

        .right-fade {
            background: linear-gradient(90deg, rgba(16, 20, 38, 0) 50%, rgba(16, 20, 38, 1) 100%);
        }

        .bottom-fade {
            background: linear-gradient(180deg, rgba(16, 20, 38, 0) 50%, rgba(16, 20, 38, 1));
        }
    }
</style>