<template>
  	<router-view/>
</template>

<script>

export default {

	setup() {  }
}
</script>

<style>
	#app {
		font-family: Sorren;
		letter-spacing: 1.5px;
		padding: 1em;
	}

	@media (min-width: 640px) {
		#app {
		padding: 2em;
		}
	}
</style>
