<template>
    <footer>
        <p class="copyright">&copy; {{ getCurrentYear() }} - Gratian Sichet</p>
    </footer>
</template>

<script>
    export default {
        setup() {
            const getCurrentYear = () => {
                return new Date().getFullYear()
            }

            return { getCurrentYear }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../assets/styles/_colors.scss";

    footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: column-reverse; // same as flex-direction, can add wrap or no-wrap
        color: darken($text-color, 20%);
        font-size: clamp(1rem, 2vw, 1.2rem);
        font-weight: 200;

        @media (min-width: 640px) {
            flex-flow: row; // same as flex-direction, can add wrap or no-wrap
        }

        .copyright {
            margin: 1em auto 0;
        }

        .footer-legal__links {
            .legal-link {
                background: linear-gradient(90deg, $blue-color 50%, $text-color 50%);
                background-size: 200%;
                background-clip: text;
                background-position: right;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin: 0 .3em;
                transition: 0.5s;

                &:hover {
                    background-position: left;
                }
            }
        }
    }
</style>