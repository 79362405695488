<template>
    <section class="contact">
        <p class="contact-title">Vous avez un projet en tête? N'hésitez pas à me contacter.</p>
        <a :href="`mailto:${contactMail}`" class="contact-subtitle">{{ contactMail }}</a>
    </section>
</template>

<script>
    export default {
        setup() {
            const contactMail = 'contact@gratian.dev'

            return { contactMail }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../assets/styles/_colors.scss";
    @import "../assets/styles/_animations.scss";

    .contact {
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column; // same as flex-direction, can add wrap or no-wrap

        @media (min-width:640px) {
            height: 100vh;
        }

        .contact-title {
            font-size: clamp(1.2rem, 3vw, 1.5rem);
            letter-spacing: 1.5px;
            text-align: center;
        }

        .contact-subtitle {
            font-family: Sign;
            padding: 0 .5em;
            font-size: clamp(2.5rem, 7vw, 8rem);
            background-size: 200% !important;
            background-image: linear-gradient(30deg, $green-color, $blue-color, $green-color);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: GradientText 10s ease-in-out infinite !important;
            letter-spacing: 3px;
        }
    }
</style>