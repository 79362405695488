<template>
	<!-- <button @click="darkMode" class="dark-mode-btn">Dark Mode</button> -->
	<Header />
	<Projects />
	<About />
	<Contact />
	<Footer />
	<Social />
</template>

<script>
	// @ is an alias to /src
	import Header from '@/components/Header.vue'
	import Projects from '@/components/Projects.vue'
	import About from '@/components/About.vue'
	import Contact from '@/components/Contact.vue'
	import Footer from '@/components/Footer.vue'
	import Social from '@/components/Social.vue'

	export default {
		name: 'Home',
		components: {
			Header,
			Projects,
			About,
			Contact,
			Footer,
			Social
		},
		setup() { 
			const darkMode = () => {
				const body = document.body
				const darkModeBtn = document.querySelector('.dark-mode-btn')

				darkModeBtn.addEventListener('click', () => {
					console.log('clicked')
					body.classList.toggle('dark-mode')
				})
			}

			return { darkMode }
		}
	}	
</script>

<style lang="scss" scoped>
	@import "../assets/styles/_colors.scss";
	@import "../assets/styles/_animations.scss";

	header {
		height: 80vh;

		@media(min-width: 640px) {
			height: 100vh;
		}
	}

	.dark-mode-btn {
		position: absolute;
	}

	.dark-mode {
		background-color: $text-color !important;
		color: $background-color !important;
	}
</style>